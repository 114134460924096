import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import ReactGA from "react-ga4";
// helpers
import { getTimeslotModalTitle } from "./helpers/getTimeslotModalTitle";
import { getTimeslotText } from "./helpers/getTimeslotText";
import { isDateInStringIsValid } from "../../../../common/utils/helpers/isDateInStringIsValid";
import { allDayOfferType } from "../../../../common/utils/helpers/globalConstants";
import { omitFromObject } from "../../../../common/utils/helpers/omitFromObject";
// components
import AppModal from "../../../../components/ui/AppModal";
import ThreeDotsLoader from "../../../../components/ui/ThreeDotsLoader";

interface OfferDetailsTimeslotsModalProps {
  timeslots: Array<any> | undefined;
  isOpen: boolean;
  toggleModal: (state: boolean) => void;
  offerFormData: any;
}

export default function OfferDetailsTimeslotsModal({
  timeslots,
  isOpen,
  toggleModal,
  offerFormData
}: OfferDetailsTimeslotsModalProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const language = localStorage.getItem("i18nextLng");

  // states
  const offer = useSelector((state: any) => state.offerDetails.offer);
  const isLoadingTimeslots = useSelector((state: any) => state.offerDetails.isLoadingTimeslots);

  const handleOpenModal = (): void => {
    toggleModal(true);
  };

  const handleCloseModal = (): void => {
    toggleModal(false);
  };

  const handleTimeslot = (slot: string, offer: any) => {
    // GA4 - Track when user select timeslot
    ReactGA.gtag("event", "add_to_cart", {
      items: [
        {
          item_id: offer?.offer_code,
          item_name: offer?.title
        }
      ]
    });
    navigate(`/${language}/offers/${offer?.offer_code}/checkout`, {
      state: {
        slot: slot,
        offerFormData: offerFormData?.original,
        offer: offer
      }
    });
    toggleModal(false);
  };

  const getDataForRender = () => {
    // remove "original" from offerFormData, in order to render just translated values
    return omitFromObject(offerFormData, "original");
  };

  const renderElement = (): any => {
    return timeslots?.length ? (
      <Box>
        <Box className="timeslot-content">
          {timeslots?.map(slot => {
            return (
              <Box
                key={slot?.from}
                className={`timeslot ${!offer?.directly_bookable ? "timeslot--yellow" : undefined} ${
                  offer?.event_type === allDayOfferType ? "timeslot--full-width" : undefined
                } `}
                onClick={() => handleTimeslot(slot?.from, offer)}
                data-cy={getTimeslotText(slot?.from, offer, t)}
              >
                {getTimeslotText(slot?.from, offer, t)}
              </Box>
            );
          })}
        </Box>
        <Box className="flex-center timeslot-info-box" mt={2}>
          {t("offerDetails.timeslots.yourSelection")}:
          {Object.entries(getDataForRender()).map(([key, value], index: number) => {
            return (
              <Box key={key} pl={0.7}>
                {renderSelectionEntries(key, value)}
                {Object.entries(getDataForRender())?.length !== index + 1 && ","}
              </Box>
            );
          })}
        </Box>
      </Box>
    ) : (
      <div>{t("offerDetails.timeslots.notAvailable")}</div>
    );
  };

  const renderSelectionEntries = (key: string, value: any) => {
    const isDate: boolean = isDateInStringIsValid(value);
    const valueString: string = isDate ? format(new Date(value), "dd.MM.yyyy") : value;
    return `${key}: ${valueString}`;
  };

  return (
    <AppModal
      title={getTimeslotModalTitle(isLoadingTimeslots, timeslots, offer, t)}
      modalSize="md"
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      handleOpenModal={handleOpenModal}
    >
      <Box className="flex-center timeslot-modal">
        {isLoadingTimeslots ? <ThreeDotsLoader customClass="timeslot-modal__loader" /> : renderElement()}
      </Box>
    </AppModal>
  );
}
