// compare 2 objects (formData and schemaProperties) from JSON schema, in order to get object formData, but with translated title.
// but we are also sending "original", that we are using for EPs properties/payload --> and others are for translation purpose
export const getRepackedFormDataObj = (formData: any, schemaProperties: any): any => {
  if (!formData || !schemaProperties) {
    return null;
  }
  const repackagedTitlesArr = Object.entries(formData).map(([key, val]) => {
    return {
      original: formData,
      [schemaProperties[key].title]: val
    };
  });

  return Object.assign({}, ...repackagedTitlesArr);
};
