import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { JSONSchema7 } from "json-schema";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/mui";
import ReactGA from "react-ga4";
// metadata
import { Helmet, HelmetProvider } from "react-helmet-async";
// hooks
import { useActions } from "../../../common/hooks/useActions";
// components
import AppGoogleMap from "../../../components/AppGoogleMap";
import AppSlider from "../../../components/AppSlider";
import OfferDetailsTimeslotsModal from "./OfferDetailsTimeslotsModal";
import AppButton from "../../../components/ui/AppButton";
import CustomFormDropdown from "../../../components/ui/AppForm/CustomFormDropdown";
import AppDatePicker from "../../../components/AppDatePicker";
import AppIconBox from "../../../components/ui/AppIconBox";
import SectionHeadingBox from "../../../components/ui/SectionHeadingBox";
import AppReadMore from "../../../components/AppReadMore";
import AppOffers from "../../../components/ui/AppOffers";
// Schema components
import AvailabilityFieldsTemplate from "../../Booking/BookingSchemas/AvailabilityFieldsTemplate";
// actions
import { offerDetailsActionCreators } from "./offerDetailsModule";
// helpers
import { getIconImage } from "./helpers/getIconImage";
import { getLocationText } from "./helpers/getLocationText";
import { getRepackedFormDataObj } from "./helpers/getRepackedFormDataObj";
// icons
import { exportLaunchIcon } from "../../../common/vector";

export default function OfferDetails() {
  const { code } = useParams<{ language: string; code: string }>();

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const language = localStorage.getItem("i18nextLng");

  // local state
  const [isTimeslotsModalOpen, setIsTimeslotsModalOpen] = useState(false);
  const [offerFormData, setOfferFormData] = useState(null);
  const [bookingSchemaFormData, setBookingSchemaFormData] = useState(location?.state?.offerFormData);
  const [uiSchema, setUiSchema] = useState(null);
  const [offerOptionCode, setOfferOptionCode] = useState(code);

  // Load more
  const maxContentLengthToShowButton = 450; // Set your desired length limit
  const [expandedShortDescription, setExpandedShortDescription] = useState(false);

  // states
  const offer = useSelector((state: any) => state.offerDetails.offer);
  const offerSchema = useSelector((state: any) => state.offerDetails.offerSchema);
  const timeslots = useSelector((state: any) => state.offerDetails.timeslots);
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const googleMapApiKey = useSelector((state: any) => state.global.googleMapApiKey);

  // actions
  const getOfferDetails = useActions(offerDetailsActionCreators?.getOfferDetailsAction, []);
  const resetGetOfferDetails = useActions(offerDetailsActionCreators?.resetOfferDetailsAction, []);
  const getTimeslots = useActions(offerDetailsActionCreators?.getTimeslotsAction, []);

  const bookingSchemaFields = {
    dropdown: CustomFormDropdown,
    date: AppDatePicker
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (hotelInfo) {
      // If coming from Explore --> use location?.state.
      // If coming directly from link (example: testing.get-local.tech/offers/ezXPbx3H) --> use path name "/offers/{code}
      const pathUrl = location?.state?.offer_code
        ? `/offers/${location?.state?.offer_code}`
        : `/offers/${offerOptionCode}`;
      getOfferDetails(pathUrl, navigate);
    }
  }, [hotelInfo, language, offerOptionCode]);

  useEffect(() => {
    return () => {
      resetGetOfferDetails();
    };
  }, []);

  useEffect(() => {
    navigate(`/${language}/offers/${offerOptionCode}`, { replace: true });
  }, [language]);

  useEffect(() => {
    navigate(`/${language}/offers/${offerOptionCode}`, { replace: true });
  }, [offerOptionCode]);

  useEffect(() => {
    // Handling UI Schema definition of properties
    // TBD: MOVE THIS TO SEPARATE HELPER AFTER IS APPROVED AND AFTER BACKEND CHANGES (to include category to JSON Schema properties)
    const uiSchemaFields: any = {};

    if (offerSchema?.json_schema?.properties) {
      // Filtered properties for dropdowns fields
      // Filter the properties based on the criteria
      const filteredDropdownProperties = Object.keys(offerSchema?.json_schema.properties).filter(
        key => offerSchema.json_schema.properties[key].enum
      );

      filteredDropdownProperties.forEach((key: any) => {
        uiSchemaFields[key] = {
          "ui:field": "dropdown",
          "ui:options": {
            props: {
              layout: "vertical",
              labelColor: "white"
            }
          }
        };
      });
      // Filtered properties for date fields
      const filteredDateProperties = Object.keys(offerSchema?.json_schema.properties).filter(
        key => offerSchema.json_schema.properties[key].format === "date"
      );

      filteredDateProperties.forEach((key: any) => {
        uiSchemaFields[key] = {
          "ui:field": "date"
        };
      });
      // ADD OTHER UI DEFINITION TO SCHEMA
      uiSchemaFields["ui:options"] = { label: false }; // hide title and description,

      setUiSchema(uiSchemaFields);
    }
  }, [offerSchema]);

  useEffect(() => {
    // GA4 - Track when user opens offer and see the content
    if (offer?.offer_code) {
      ReactGA.gtag("event", "view_item", {
        items: [
          {
            item_id: offer?.offer_code,
            item_name: offer?.title,
            item_category: offer?.categories?.length > 0 && offer?.categories[0]?.id
          }
        ]
      });
    }
  }, [offer]);

  const toggleContent = () => {
    setExpandedShortDescription(!expandedShortDescription);
  };

  const toggleTimeslotsModal = (state: boolean): void => setIsTimeslotsModalOpen(state);

  const onSubmitAvailabilityForm = (event: any): void => {
    getTimeslots(offer?.offer_code, event?.formData);
    setOfferFormData(getRepackedFormDataObj(event?.formData, event.schema.properties));
    toggleTimeslotsModal(true);
  };

  const onOfferClick = (code: string) => {
    resetGetOfferDetails();
    setOfferOptionCode(code);
  };

  const showMap: boolean = googleMapApiKey && offer?.address?.latitude && offer?.address?.longitude;

  return (
    <Grid
      className="offer-details grid-box"
      container
      columnSpacing={{ xs: 0, sm: 3, md: 6 }}
      mt={{ xs: 1, sm: 2 }}
      mb={{ xs: 4, sm: 5 }}
      pl={{ xs: 5 }}
      pr={{ xs: 5 }}
    >
      {/* Set metadata */}
      <HelmetProvider>
        <Helmet>
          <title>{`${hotelInfo?.name} : ${offer?.title}`}</title>
          <meta name="description" content={`${offer?.short_description}`} />
          {/* Open Graph tags */}
          <meta property="og:title" content={`${hotelInfo?.name} : ${offer?.title}`} />
          <meta property="og:description" content={`${offer?.short_description}`} />
        </Helmet>
      </HelmetProvider>
      {/* // */}
      <Grid item xs={12} sm={12} lg={5}>
        <AppSlider
          images={offer?.images}
          defaultImage={hotelInfo?.style?.logo}
          customColor={hotelInfo?.style?.page?.main_color}
        />
      </Grid>

      <Grid item xs={12} sm={12} lg={7}>
        <Grid container columnSpacing={{ md: 6, lg: 0 }}>
          <Grid item xs={12} md={6} lg={12} mt={{ xs: 2, lg: 0 }}>
            <SectionHeadingBox heading={offer.title} subheading={offer?.provider?.label} />
            <Grid container columnSpacing={{ xs: 2, md: 2, lg: 6 }} className="flex-start">
              {offer?.icons?.length > 0 &&
                offer?.icons?.map((icon: any) => {
                  return (
                    <AppIconBox
                      key={icon.name}
                      description={icon.description}
                      id={icon.name}
                      name={icon.name}
                      imageSource={require(`./../../../common/vector/${getIconImage(icon.name)}`)}
                      value={icon.category === "duration" ? icon.value : Number(icon.value)?.toFixed(2)}
                      valueLabel={icon.category === "price" && icon.currency}
                    />
                  );
                })}
            </Grid>

            <Box
              className={`offer-details__content  ${
                offer.short_description?.length > maxContentLengthToShowButton && !expandedShortDescription
                  ? "offer-details__content--with-load-more"
                  : ""
              } ${!expandedShortDescription ? "offer-details__content--with-max-height" : ""}`}
              mt={{ xs: 2, md: 3, lg: 4 }}
            >
              {offer.short_description}
            </Box>
            {offer.short_description?.length > maxContentLengthToShowButton && (
              <Box className="offer-details--with-read-more" mt={1} onClick={toggleContent}>
                <AppReadMore toggleCollapsible={expandedShortDescription} />
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={12} mt={{ md: "65px", lg: 0 }}>
            {offerSchema?.json_schema && (
              <Box className="offer-details__availability-box" mt={{ xs: 3, md: 4 }}>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <Box className="flex offer-details__availability-box__title">{offerSchema?.json_schema?.title}</Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box
                      className="flex offer-details__availability-box__subtitle"
                      ml={{ lg: 3 }}
                      mt={{ xs: 1, lg: 0 }}
                    >
                      {offerSchema?.json_schema?.description}
                    </Box>
                  </Grid>
                </Grid>
                <Form
                  formData={bookingSchemaFormData}
                  onChange={e => setBookingSchemaFormData(e.formData)}
                  fields={bookingSchemaFields}
                  templates={{ ObjectFieldTemplate: AvailabilityFieldsTemplate }}
                  schema={offerSchema?.json_schema as JSONSchema7}
                  uiSchema={uiSchema || undefined}
                  validator={validator}
                  onSubmit={onSubmitAvailabilityForm}
                >
                  {offerSchema?.json_schema?.properties ? (
                    <Box width={{ xs: "100%", lg: 105, xl: 143 }} sx={{ marginTop: "auto" }} height={40}>
                      <AppButton
                        label={t("offerDetails.check")}
                        onClick={() => null}
                        disableRipple={true}
                        customBackgroundColor={hotelInfo?.style?.button?.color}
                        customActiveColor={hotelInfo?.style?.button?.active}
                        customHoverColor={hotelInfo?.style?.button?.hover}
                        customClickColor={hotelInfo?.style?.button?.click}
                        type="submit"
                        fontSize="14px"
                        dataAttribute="check-availability-button"
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Form>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      {offer?.offer_options?.length > 0 && (
        <Grid mt={{ xs: 4, md: 6 }} item xs={12} sm={12}>
          {/* TODO: ADD TRANSLATION FOR OTHER LANGUAGES */}
          <div className="offer-details__heading">{t("offerDetails.offerOptionsHeading")}</div>
          <div className="offer-details__description">{t("offerDetails.offerOptionsSubheading")}</div>
          <div className="offer-details__content">
            <AppOffers onOfferClick={onOfferClick} offers={offer?.offer_options} />
          </div>
        </Grid>
      )}
      {offer?.high_light && (
        <Grid mt={{ xs: 4, md: 6 }} item xs={12} sm={12}>
          <div className="offer-details__heading">{t("offerDetails.highlightsHeading")}</div>
          <div
            className="offer-details__content offer-details__content--with-html-elements"
            dangerouslySetInnerHTML={{
              __html: offer?.high_light
            }}
          />
        </Grid>
      )}
      {offer?.long_description && (
        <Grid mt={{ xs: 4, md: 6 }} item xs={12} sm={12}>
          <div className="offer-details__heading">{t("offerDetails.detailsHeading")}</div>
          <div
            className="offer-details__content offer-details__content--with-html-elements"
            dangerouslySetInnerHTML={{
              __html: offer?.long_description
            }}
          />
        </Grid>
      )}
      <Grid mt={{ xs: 4, md: 6 }} item sx={{ display: "flex", flex: 1 }}>
        <div className="offer-details__content">
          <Grid container>
            <Grid item xs={12} lg={4} mb={{ xs: 2, lg: 0 }}>
              <div className="offer-details__heading">{t("offerDetails.locationHeading")}</div>
              <div className="offer-details__content offer-details__content--location-subheading">
                {t("offerDetails.locationSubheading")}
              </div>
              <div className="offer-details__content">{getLocationText(offer?.address)}</div>
            </Grid>
            <Grid item xs={12} lg={8} sx={{ minHeight: showMap ? 315 : "auto" }}>
              {showMap && (
                <AppGoogleMap
                  apiKey={googleMapApiKey}
                  lat={offer?.address?.latitude}
                  lng={offer?.address?.longitude}
                  showMarker={true}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
      {offer?.cancellation_policy && (
        <Grid mt={{ xs: 4, md: 6 }} item xs={12} sm={12}>
          <div className="offer-details__heading">{t("offerDetails.cancellationHeading")}</div>
          <div className="offer-details__content">{offer?.cancellation_policy}</div>
        </Grid>
      )}
      {offer?.pdfs && offer.pdfs?.length > 0 && (
        <Grid mt={{ xs: 4, md: 6 }} item xs={12} sm={12}>
          <div className="offer-details__heading">{t("offerDetails.moreInfosHeading")}</div>
          <div className="offer-details__content">
            <div className="offer-details__pdfs-box">
              {offer?.pdfs?.map((pdf: any, index: number) => {
                return (
                  <a
                    className="offer-details__pdfs-box__item"
                    href={pdf.link}
                    target="_blank"
                    key={index}
                    rel="noreferrer"
                  >
                    {pdf.label ? pdf.label : "PDF File"}
                    <img className="export-launch-icon" src={exportLaunchIcon.default} alt="export-launch-icon" />
                  </a>
                );
              })}
            </div>
          </div>
        </Grid>
      )}
      {offer?.links && offer?.links?.length > 0 && (
        <Grid mt={{ xs: 4, md: 6 }} item xs={12} sm={12}>
          <div className="offer-details__heading">{t("offerDetails.additionalInformationHeading")}</div>
          <div className="offer-details__content offer-details__content--links">
            {offer?.links?.map((link: any) => (
              <a href={link?.link} target="_blank" key={link?.link}>
                {link?.label}
                <img src={exportLaunchIcon.default} alt="export-launch-icon" />
              </a>
            ))}
          </div>
        </Grid>
      )}
      {offer?.provider && (
        <Grid mt={{ xs: 4, md: 6 }} item xs={12} sm={12}>
          <div className="offer-details__heading">{t("offerDetails.serviceProviderHeading")}</div>
          <div className="offer-details__content offer-details__content--service-provider">
            <span>{offer?.provider?.label}</span>
            {offer?.provider_logo_url && (
              <img src={offer?.provider_logo_url} alt="provider-logo" className="offer-details__provider-logo" />
            )}
          </div>
        </Grid>
      )}

      {isTimeslotsModalOpen && (
        <OfferDetailsTimeslotsModal
          isOpen={isTimeslotsModalOpen}
          timeslots={timeslots}
          toggleModal={toggleTimeslotsModal}
          offerFormData={offerFormData}
        />
      )}
    </Grid>
  );
}
